import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import NoAccess from "./pages/studentPages/routeInformationPages/noAccess/NoAccess";
import NotFound from "./pages/studentPages/routeInformationPages/pageNotFound/NotFound";
import { UserContext } from "./context/UserContext";
import {
  IProtectedRoutesInterface,
  ISubcribeRoutes,
} from "./models/componentProps/IProtectedRoutesInterface";
import Navbar from "./components/general/navbar/Navbar";
import { navbarDataList } from "./datalists/navbarProps";
// import Footer from "./components/footer/Footer";
import { ActivateAccount } from "./pages/ActivateAccount";
import { AddRegisterPayment } from "./pages/companyPages/addRegisterPayment/AddRegisterPayment";
import { ConfirmProvider } from "material-ui-confirm";
import CookieConsent from "react-cookie-consent";
import { Success } from "./pages/companyPages/payment/Success";
import { ResetPassword } from "./pages/ResetPassword";
import { Offer } from "./pages/companyPages/payment/Offer";
import { SchoolUserProfileCreated } from "./pages/school/schoolUserProfileCreated/SchoolUserProfileCreated";
import { SchoolUserProfile } from "./pages/school/schoolUserProfile/SchoolUserProfile";
import { SchoolUserSearchStudents } from "./pages/school/SchoolUserSearchStudents/SchoolUserSearchStudents";
//import  SchoolUserUlOverview  from "./pages/school/schoolUserUlOverview/SchoolUserUlOverview"
import Loader from "./components/general/Loader/Loader";
import { Unsubscribe } from "./pages/companyPages/payment/Unsubscribe";
import { ChangePassword } from "./pages/general/changePassword/ChangePassword";
// import InterestShown from "./pages/studentPages/interestShownComplete/InterestShown";
// import OfferAccepted from "./pages/studentPages/offerAccepted/OfferAccepted";
import ReactGA4 from "react-ga4";
import PopupSubscription from "./components/subscriptionPopup/PopupSubscription";
import PopupSubscriptionWithStripe from "./components/subscriptionPopup/PopupSubscriptionWithStripe";
import { phoneNumber } from "./constants/PhoneNumber";
import { StudentSuccess } from "./pages/studentPages/studentSuccess/StudentSuccess";
import { Helmet } from "react-helmet";

import { MessagingReducer } from "./reducers/messagingReducer";
import { BLANK_ICONVERSATION_RESPONSE } from "./constants/blankData/general/blankConversation";
import {
  MessageContext,
  MessageDispatchContext,
} from "./context/MessageContext";
import AdComponent from "./components/adComponent/adComponent";
import { GOOGLE_SLOT_RESPONSIVE } from "./pages/general/ads/googleClient";
import BannerAd from "./components/bannerAd/bannerAd";

// ################# GENERAL IMPORTS #################
const NewVerificationEmail = lazy(
  () => import("./pages/general/resendVerificationEmail/NewVerificationEmail")
);
// ##################################################

// ################# INTERN IMPORTS #################
const InternProfile = lazy(
  () => import("./pages/intern/internProfile_NEW/InternProfile")
);
const InternAddEducation = lazy(
  () => import("./pages/intern/internAddEducation/InternAddEducation")
);

const AllWorkExperience = lazy(
  () => import("./pages/intern/allWorkExperience/AllWorkExperience")
);

const StudentSearch = lazy(
  () => import("./pages/intern/studentSearch/StudentSearch")
);
// ##################################################

const StudentViewOffers = lazy(
  () => import("./pages/studentPages/studentViewOffers/StudentViewOffers")
);
const ViewStudentInterestsForCompany = lazy(
  () =>
    import(
      "./pages/studentPages/viewStudentInterestsForCompany/ViewStudentInterestsForCompany"
    )
);
const ViewCompanyInternshipForStudent = lazy(
  () =>
    import(
      "./pages/companyPages/viewCompanyInternshipForStudent/ViewCompanyInternshipForStudent"
    )
);
const Payment = lazy(() => import("./pages/companyPages/payment/payment"));
const PublicProfile = lazy(
  () => import("./pages/studentPages/publicProfile/PublicProfile")
);
const CompanyProfilePage = lazy(
  () => import("./pages/company/companyProfile/CompanyProfile")
);
const CompanyJobAds = lazy(
  () => import("./pages/company/companyProfile/CompanyJobsAds")
);
const SearchPage = lazy(() => import("./pages/company/searchPage/SearchPage"));
const AllPostsView = lazy(
  () => import("./pages/companyPages/viewAllNewPosts/AllPostsView")
);
const CompanyInternship = lazy(
  () => import("./pages/companyPages/CompanyInternship/CompanyInternship")
);
const HalfWayInfoPageCompany = lazy(
  () => import("./pages/companyPages/halfWayInfoCompany/HalfWayInfoPageCompany")
);
const AddPicCompany = lazy(
  () => import("./pages/companyPages/addPicCompany/AddPicCompany")
);

const CompanyDetails = lazy(
  () => import("./pages/company/companyDetails/CompanyDetails")
);

const ContactDetails = lazy(
  () => import("./pages/companyPages/contactDetails/ContactDetails")
);
const StudentMyAccount = lazy(
  () => import("./pages/intern/internProfile/InternProfile")
);
const FindInternperiods = lazy(
  () => import("./components/internPeriod/findInterns/FindInternperiods")
);
const StepsCompleted = lazy(
  () => import("./pages/studentPages/stepsCompleted/StepsCompleted")
);
const HalfwayInfoPage = lazy(
  () => import("./pages/studentPages/halfWayInfo/HalfwayInfoPage")
);
const AddSkills = lazy(() => import("./pages/intern/addSkills/AddSkills"));

const AddProfilePic = lazy(
  () => import("./pages/intern/addProfilePic/AddProfilePic")
);
const AddPersonal = lazy(
  () => import("./pages/intern/addPersonal/AddPersonal")
);
const AddNewPost = lazy(
  () => import("./pages/intern/addInternPeriod/AddInternPeriod")
);

const AddEmployment = lazy(
  () => import("./pages/intern/addInternPeriod/addEmployment")
);

const AddExperience = lazy(
  () => import("./pages/intern/addExperience/AddExperience")
);

const SchoolUserAddInfo = lazy(
  () => import("./pages/school/schoolUserAddInfo/SchoolUserAddInfo")
);

const SchoolUserAddLogo = lazy(
  () => import("./pages/school/schoolUserAddLogo/SchoolUserAddLogo")
);

const CompanyPublicProfile = lazy(
  () => import("./pages/companyPages/publicProfile/CompanyPublicProfile")
);
const SchoolUserUlOverview = lazy(
  () =>
    import("./pages/school/schoolUserStudentOverview/SchoolUserStudentOverview")
);
const Landing = lazy(() => import("./pages/general/landing/Landing"));
const Login = lazy(() => import("./pages/general/login/Login"));

const Register = lazy(() => import("./pages/general/register/Register"));
const Intern = lazy(() => import("./components/internPeriod/Intern"));
const Admin = lazy(() => import("./components/admin/Admin"));
const SignupPaymentPage = lazy(
  () =>
    import("./pages/companyPages/payment/signupPaymentPage/SignupPaymentPage")
);
const MyFavoritesPage = lazy(
  () => import("./pages/general/myFavorites/MyFavoritesPage")
);
const MyApplications = lazy(
  () => import("./pages/general/myFavorites/myApplications/Myapplications")
);
const OldMessaging = lazy(() => import("./pages/message/TalkJs"));

const Messaging = lazy(() => import("./pages/message/Messaging"));

const FavoritedStudents = lazy(
  () => import("./pages/studentPages/favoritedStudents/FavoritedStudents")
);

const InterestingCompanies = lazy(
  () => import("./pages/intern/interestingCompanies/InterestingCompanies")
);
const BookedInterns = lazy(
  () => import("./pages/general/myFavorites/bookedInterns/BookedInterns")
);

const ShownInterest = lazy(
  () => import("./pages/general/myFavorites/shownInterest/ShownInterest")
);
const SavedCandidates = lazy(
  () => import("./pages/general/myFavorites/savedCandidates/SavedCandidates")
);

const Router = () => {
  const { user, loading, companySubscription } = useContext(UserContext);
  const [role, setRole] = useState<string | undefined>("");
  const [messages, dispatch] = useReducer(MessagingReducer, {
    socket: null,
    conversations: [],
    messages: [],
    selectedConversation: undefined,
    hasUnreadMessages: false,
    loading: false,
  });

  const settingRole = useCallback(() => {
    if (user.role !== "") setRole(user.role);
  }, [user]);

  useEffect(() => {
    settingRole();
  }, [role, user, settingRole]);

  const CheckIfStudent = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let isStudent = false;
      if (role === "Student" || role === "Admin") {
        isStudent = true;
      }

      if (!loading) {
        return isStudent ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  const CheckIfCompany = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let isCompany = false;

      if (role === "Company" || role === "Admin") {
        isCompany = true;
      }

      if (!loading) {
        return isCompany ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  const CheckIfSchool = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      // console.log("in check if school", role);
      let isSchool = false;
      if (role === "School" || role === "Admin") {
        isSchool = true;
      }

      if (!loading) {
        return isSchool ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  const CheckIfCompanyOrSchool = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let isCompOrSchool = false;
      if (role === "Company" || role === "School" || role === "Admin") {
        isCompOrSchool = true;
      }

      if (!loading) {
        return children;
      }
      return <Loader></Loader>;
    },
    [role, loading, companySubscription]
  );

  const CheckIfAdmin = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let isAdmin = false;
      if (role === "Admin") {
        isAdmin = true;
      }
      if (!loading) {
        return isAdmin ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  const CheckIfHasRole = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let hasRole = false;
      if (
        role === "Admin" ||
        role === "School" ||
        role === "Company" ||
        role === "Student"
      ) {
        hasRole = true;
      }
      if (!loading) {
        return hasRole ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  const CheckIfNoRole = useCallback(
    ({ children, redirectTo }: IProtectedRoutesInterface) => {
      let isNoRole = false;
      if (role === "" || role === undefined || role === null) {
        isNoRole = true;
      }
      if (!loading) {
        return isNoRole ? children : <Navigate to={redirectTo} />;
      }
      return <Loader></Loader>;
    },
    [role, loading]
  );

  ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <BrowserRouter>
      <CookieConsent buttonText="Jag förstår">
        Denna webbplats använder cookies för att förhöja din upplevelse
      </CookieConsent>

      <MessageDispatchContext.Provider value={dispatch}>
        <MessageContext.Provider value={messages}>
          <Navbar data={navbarDataList} userRole={user.role} />
          <div style={{ marginTop: "9vh" }}>
            {/* <BannerAd /> */}
            {/* <AdComponent width="100%" height="100%" adslot={GOOGLE_SLOT_RESPONSIVE} format={"auto"} /> */}
          </div>
          <ConfirmProvider>
            <Routes>
              {/* #################################### GENERAL ROUTE BELOW #################################### */}
              <Route // SEND VERIFICATION EMAIL
                path="/new-verification-email"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Verifiera email</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>
                      <NewVerificationEmail />
                    </Suspense>
                  </>
                }
              />
              <Route
                path="/archived-messages"
                element={
                  <CheckIfHasRole redirectTo="/access-denied">
                    <Suspense fallback={<Loader />}>
                      {<OldMessaging />}
                    </Suspense>
                  </CheckIfHasRole>
                }
              />
              <Route
                path="/messages"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Meddelanden</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>{<Messaging />}</Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              <Route
                path="/favorites"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Favoriter</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <MyFavoritesPage />
                      </Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              <Route
                path="/favorites/myapplications"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Mina ansökningar</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <MyApplications />
                      </Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              <Route
                path="/favorites/booked"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Bokade</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <BookedInterns />
                      </Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              <Route
                path="/favorites/showninterest"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Visat intresse</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <ShownInterest />
                      </Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              <Route
                path="/favorites/candidates"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Favoriter</title>
                    </Helmet>
                    <CheckIfHasRole redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SavedCandidates />
                      </Suspense>
                    </CheckIfHasRole>
                  </>
                }
              />
              {/* ############################################################################################ */}
              {/* #################################### INTERN ROUTES BELOW #################################### */}
              <Route
                path="/intern/profile"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Profil</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <InternProfile />
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addeducation"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till utbildning</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <InternAddEducation />
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addprofileimage"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till profilbild</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<AddProfilePic />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addinternperiod"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Praktikperiod</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<AddNewPost />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addemployment"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till anställning</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<AddEmployment />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addskills"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Vad vill du jobba med?</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>{<AddSkills />}</Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addexperiences"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till erfarenhet</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<AddExperience />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/addpersonal"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till personlig info</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<AddPersonal />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/intern/interesting-companies"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Välj intressanta företag</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <InterestingCompanies />
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/student/search"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Sök företag</title>
                    </Helmet>

                    <Suspense fallback={<Loader />}>
                      <StudentSearch />
                    </Suspense>
                  </>
                }
              >
                <Route
                  path=":search"
                  element={
                    <>
                      <Helmet>
                        <title>Hopen | Sök företag</title>
                      </Helmet>

                      <Suspense fallback={<Loader />}>
                        <StudentSearch />
                      </Suspense>
                    </>
                  }
                />
              </Route>
              <Route
                path="/student/profile" // vit sida
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Student profil</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<StudentMyAccount />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/student/viewoffers"
                element={<StudentViewOffers />}
              />
              {/* ############################################################################################ */}
              {/* #################################### COMPANY ROUTES BELOW #################################### */}
              <Route
                path="/addinfo"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till information</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<HalfwayInfoPage />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/studentcomplete"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Tack för din registrering</title>
                    </Helmet>
                    <CheckIfStudent redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<StepsCompleted />}
                      </Suspense>
                    </CheckIfStudent>
                  </>
                }
              />
              <Route
                path="/company/search"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Sök praktikanter</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>{<SearchPage />}</Suspense>
                  </>
                }
              >
                <Route
                path=":search"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Sök praktikanter</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>{<SearchPage />}</Suspense>
                  </>
                }
                />
              </Route>
              <Route
                path="/company/profile"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Profil</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <CompanyProfilePage />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/myjobs"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Ansökningar</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <CompanyJobAds />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/addpayment"
                element={
                  <CheckIfCompany redirectTo="/access-denied">
                    <Suspense fallback={<Loader />}>
                      {<AddRegisterPayment />}
                    </Suspense>
                  </CheckIfCompany>
                }
              />
              <Route
                path="/company/internship/:id"
                element={
                  <CheckIfStudent redirectTo="/access-denied">
                    <Suspense fallback={<Loader />}>
                      <ViewCompanyInternshipForStudent />
                    </Suspense>
                  </CheckIfStudent>
                }
              />
              <Route
                path="/contact"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Kontaktuppgifter</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<ContactDetails />}
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/page/:id"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Företag</title>
                    </Helmet>

                    <Suspense fallback={<Loader />}>
                      <CompanyPublicProfile />
                    </Suspense>
                  </>
                }
              ></Route>
              {/* Refactor /company/description and /company/invoice into one /company/details */}
              <Route
                path="/company/details"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Beskriv företaget</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<CompanyDetails />}
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/offer"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Betalning misslyckades</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>{<Offer />}</Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/student/page/:id"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Student</title>
                    </Helmet>
                    <CheckIfCompanyOrSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <PublicProfile />
                      </Suspense>
                    </CheckIfCompanyOrSchool>
                  </>
                }
              />
              <Route
                path="/student/page/favorited"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Favoriter</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <FavoritedStudents />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/addlogo"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till logo</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <AddPicCompany />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/halfway"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Halvvägs klar</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <HalfWayInfoPageCompany />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/internship"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Vad letar du efter?</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <CompanyInternship />
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/viewstudentsinterests"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Stundenters intresse</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>
                      <ViewStudentInterestsForCompany />
                    </Suspense>
                  </>
                }
              />
              <Route
                path="/company/subscribe"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Prenumerera</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        {<SignupPaymentPage />}
                      </Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/payment"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Börja prenumerera</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>{<Payment />}</Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/payment/success"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Prenumeration lyckades</title>
                    </Helmet>
                    <CheckIfCompany redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>{<Success />}</Suspense>
                    </CheckIfCompany>
                  </>
                }
              />
              <Route
                path="/company/payment/unsubscribe"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Avsluta prenumeration</title>
                    </Helmet>
                    <Unsubscribe />
                  </>
                }
              />
              <Route
                path="/student/payment/success"
                element={
                  <CheckIfStudent redirectTo="/access-denied">
                    <Suspense fallback={<Loader />}>
                      {<StudentSuccess />}
                    </Suspense>
                  </CheckIfStudent>
                }
              />
              {/* [//----------] //Educator routes */}
              {/* SHOULD THE ROUTES BELOW BE "educator/" OR "/school" */}
              <Route
                path="/schooluser/addinfo"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till skola</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserAddInfo />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              />
              <Route
                path="/schooluser/addlogo"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Lägg till profilbild</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserAddLogo />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              />
              <Route
                path="/schooluser/profilecreated"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Profil skapad</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserProfileCreated />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              />
              <Route
                path="/schooluser/profile"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Min profil</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserProfile />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              />
              <Route
                path="/schooluser/searchstudents"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Sök studenter</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserSearchStudents />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              >
                <Route 
                  path=":search"
                  element={
                    <>
                      <Helmet>
                        <title>Hopen | Sök studenter</title>
                      </Helmet>
                      <CheckIfSchool redirectTo="/access-denied">
                        <Suspense fallback={<Loader />}>
                          <SchoolUserSearchStudents />
                        </Suspense>
                      </CheckIfSchool>
                    </>
                  }
                />
              </Route>
              <Route
                path="/schooluser/studentOverview"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Mina studenter</title>
                    </Helmet>
                    <CheckIfSchool redirectTo="/access-denied">
                      <Suspense fallback={<Loader />}>
                        <SchoolUserUlOverview />
                      </Suspense>
                    </CheckIfSchool>
                  </>
                }
              />
              [//----------]
              <Route
                path="hitta"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Hitta praktik perioder</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>
                      <FindInternperiods />
                    </Suspense>
                  </>
                }
              />
              <Route
                path="changepassword"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Ändra lösenord</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>
                      <ChangePassword />
                    </Suspense>
                  </>
                }
              />
              <Route
                path="resetpasswordrequest"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Återställ lösenord</title>
                    </Helmet>
                    <Suspense fallback={<Loader />}>
                      <ResetPassword />
                    </Suspense>
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Landing />
                  </Suspense>
                }
              />
              {role === "" ? (
                <>
                  <Route
                    path="/login"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Logga in</title>
                        </Helmet>
                        <Suspense fallback={<Loader />}>
                          <Login />
                        </Suspense>
                      </>
                    }
                  />

                  <Route
                    path="/register"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Skapa konto</title>
                        </Helmet>
                        <CheckIfNoRole redirectTo="/access-denied">
                          <Suspense fallback={<Loader />}>
                            <Register />
                          </Suspense>
                        </CheckIfNoRole>
                      </>
                    }
                  />
                  <Route
                    path="/validateEmail"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Aktivera konto</title>
                        </Helmet>
                        <Suspense fallback={<Loader />}>
                          <ActivateAccount />
                        </Suspense>
                      </>
                    }
                  />

                  <Route
                    path="/access-denied"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Åtkomst nekad</title>
                        </Helmet>
                        <NoAccess />
                      </>
                    }
                  />
                  <Route path="*" element={<Loader />} />
                </>
              ) : (
                <>
                  <Route
                    path="/register"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Skapa konto</title>
                        </Helmet>
                        <CheckIfNoRole redirectTo="/access-denied">
                          <Suspense fallback={<Loader />}>
                            <Register />
                          </Suspense>
                        </CheckIfNoRole>
                      </>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Logga in</title>
                        </Helmet>
                        <Suspense fallback={<Loader />}>
                          <Login />
                        </Suspense>
                      </>
                    }
                  />
                  <Route
                    path="/validateEmail"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Aktiver konto</title>
                        </Helmet>
                        <Suspense fallback={<Loader />}>
                          <ActivateAccount />
                        </Suspense>
                      </>
                    }
                  />
                  <Route
                    path="/student/all-work-experience"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Tillagda erfarenheter</title>
                        </Helmet>
                        <CheckIfStudent redirectTo="/access-denied">
                          <Suspense fallback={<Loader />}>
                            {/* <AllExperiencesView /> */}
                            <AllWorkExperience />
                          </Suspense>
                        </CheckIfStudent>
                      </>
                    }
                  />

                  <Route
                    path="/new-intern-period"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Ny praktik period</title>
                        </Helmet>
                        <CheckIfStudent redirectTo="/access-denied">
                          <Suspense fallback={<Loader />}>
                            <Intern />
                          </Suspense>
                        </CheckIfStudent>
                      </>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <CheckIfAdmin redirectTo="/access-denied">
                        <Suspense fallback={<Loader />}>
                          <Admin />
                        </Suspense>
                      </CheckIfAdmin>
                    }
                  />
                  {/* is this route below used anywhere or going to be used anywhere? */}
                  <Route
                    path="/company/my-posts"
                    element={
                      <>
                        <Helmet>
                          <title>Hopen | Mina annonser</title>
                        </Helmet>
                        <CheckIfCompany redirectTo="/access-denied">
                          <Suspense fallback={<Loader />}>
                            <AllPostsView />
                          </Suspense>
                        </CheckIfCompany>
                      </>
                    }
                  />
                </>
              )}
              <Route
                path="/access-denied"
                element={
                  <>
                    <Helmet>
                      <title>Hopen | Åtkomst nekad</title>
                    </Helmet>
                    <NoAccess />
                  </>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {/* <AdComponent width="100%" height="100%" adslot={GOOGLE_SLOT_RESPONSIVE} format={"auto"} /> */}
            <BannerAd />
          </ConfirmProvider>
        </MessageContext.Provider>
      </MessageDispatchContext.Provider>
    </BrowserRouter>
  );
};

export default Router;
