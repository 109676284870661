import React, { useEffect, useState, useContext } from "react";
import { IMG_CONFIG } from "../../../config";
import { UserContext } from "../../../context/UserContext";
import {
  RiToolsFill,
  RiBriefcase3Line,
  RiUserFill,
  RiAddFill,
  RiDoubleQuotesL,
  RiBookmarkLine,
  RiBookmarkFill,
} from "react-icons/ri";
import "./studentSearchResultCard.scss";
import { AddStudentToProgram } from "../../popupAlert/AddStudenttoProgram";
import {
  addAsFavourite,
  removeAsFavourite,
} from "../../../services/favourites.service";
import { IIntern } from "../../../models_NEW_STRUCTURE/general/IIntern";
import Image from "../../general/image/Image";
import { isImageUrlValid } from "../../../helpers/StringValidations";
import { shuffle } from "../../../helpers/Shuffler";
import { useNavigate } from "react-router";
import Spinner from "../../general/spinner/Spinner";
interface IProps {
  intern: IIntern;
}
const StudentSearchResultCard = ({ intern }: IProps) => {
  const [favorite, setFavorite] = useState(false);
  const { user, companySubscription } = useContext(UserContext);
  const [openAddschool, setOpenAddSchool] = useState(false);

  const navigate = useNavigate();
  const viewMore = (id: number) => {
    navigate(`/student/page/${id}`);
    // const newTab = window.open(`/student/page/${id}`, "_blank");
    // newTab?.focus();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (intern.isFavourite) {
        setFavorite(true);
      } else return;
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const [settingFavorite, setSettingFavorite] = useState(false);

  const updateFavorite = async () => {
    setSettingFavorite(true);
    if (favorite) {
      try {
        const res = await removeAsFavourite(intern.id);
        if (res.success) {
          setSettingFavorite(false);
          return setFavorite(!favorite);
        } else return;
      } catch (error) {
        setSettingFavorite(false);
        return console.error((error as Error).message);
      }
    } else {
      try {
        const res = await addAsFavourite(intern.id);
        if (res.success) {
          setSettingFavorite(false);
          return setFavorite(!favorite);
        } else return;
      } catch (error) {
        setSettingFavorite(false);
        return console.error((error as Error).message);
      }
    }
  };

  console.log("isSubscription:", companySubscription);

  return (
    <>
      <div id="internperiod-container" onClick={() => viewMore(intern.id)}>
        <div className="internperiod-wrapper__left">
          <Image
            imageUrl={
              intern.user?.imageUrl &&
              intern.user?.imageUrl !== "" &&
              isImageUrlValid(intern.user?.imageUrl)
                ? intern.user?.imageUrl
                : IMG_CONFIG.AVATAR_LOGOS.INTERN_DEFAULT
            }
            fallbackImageUrl={IMG_CONFIG.AVATAR_LOGOS.INTERN_DEFAULT}
            imageSize={{
              width: "10rem",
              height: "10rem",
            }}
            spinnerSize="small"
          />
        </div>
        <div className="internperiod-wrapper__middle">
          {intern.user?.firstname !== "" &&
            intern.user?.firstname !== null &&
            intern.user?.lastname !== "" &&
            intern.user?.lastname !== null && (
              <div className="internperiod-wrapper__middle--name">
                <RiUserFill className="search-card-icon" />
                <p>
                  {intern.user?.firstname}
                  <span
                    className={
                      user.id === "" || !companySubscription
                        ? "blurred first-upper"
                        : ""
                    }
                  >
                    {" " +
                      (user.id === ""
                        ? shuffle(intern.user?.lastname)
                        : intern.user?.lastname)}
                  </span>
                </p>
              </div>
            )}
          {intern?.title !== "" && intern.title !== null && (
            <div className="internperiod-wrapper__middle--title">
              <RiBriefcase3Line className="search-card-icon" />
              <p className="internperiod-wrapper__middle--title">
                {intern.title?.length > 40
                  ? intern.title.slice(0, 40).concat("...")
                  : intern.title}
              </p>
            </div>
          )}
          {intern?.oneLiner !== "" && intern?.oneLiner !== null && (
            <div className="internperiod-wrapper__middle--one-liner">
              <RiDoubleQuotesL className="search-card-icon" />
              <p>{intern.oneLiner}</p>
            </div>
          )}
          {intern?.tags !== null && intern?.tags.length > 0 ? (
            <div className="skills-container">
              <RiToolsFill className="search-card-icon" />
              <div className="skills-container__tags">
                {intern.tags.map((skill) => {
                  return (
                    <button disabled key={skill.id}>
                      {skill.name}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="skills-container-empty">
              <RiToolsFill className="search-card-icon" />
              <p className="skills-container-empty__text">
                Inga kompetenser tillagda
              </p>
            </div>
          )}
        </div>
        <div className="internperiod-wrapper__right">
          {settingFavorite ?
            <Spinner size="small" />
            :
            <button
              className="favorite-btn"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (user.role === "Company") {
                  updateFavorite();
                }
                if (user.role === "School") {
                  setOpenAddSchool(true);
                }
                e.stopPropagation();
              }}
              type="button"
            >
              {user.role === "Company" ? (
                favorite ? (
                  <RiBookmarkFill className="favorited" />
                ) : (
                  <RiBookmarkLine />
                )
              ) : null}
              {user.role === "School" && <RiAddFill className="add-icon" />}
            </button>
          }{" "}
        </div>
      </div>
      <>
        {" "}
        {openAddschool && (
          <AddStudentToProgram
            title="Lägg till student till utbildning"
            yesBtnText="Spara"
            noBtnText="Avbryt"
            yesFunction={() => setOpenAddSchool(false)}
            noFunction={() => setOpenAddSchool(false)}
            idFromSearch={intern.id}
          />
        )}
      </>
    </>
  );
};
export default StudentSearchResultCard;
