import { IConversationUser } from "../../../models/messaging/IConversationResponse";
import IUser from "../../../models_NEW_STRUCTURE/general/IUser";

export const BLANK_IUSER: IUser = {
  id: "",
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  imageUrl: "",
  type: "",
  lastLoggedIn: "",
  hasUnreadMessages: false,
};

export const BLANK_CONVERSATION_USER: IConversationUser = {
  userId: "",
  name: "",
  imageUrl: "",
};
