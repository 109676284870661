import React, { useContext, useEffect, useState } from "react";
import { IoMenu, IoClose } from "react-icons/io5";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IMG_CONFIG } from "../../../config";
import { UserContext } from "../../../context/UserContext";
import { IRoutes, IUserNavbarData } from "../../../datalists/navbarProps";
import { logOut } from "../../../services/logout.service";
import useScreenSize from "../../../hooks/useScreenSize/useScreenSize";
import "./navbar.scss";
import { ICompany } from "../../../models_NEW_STRUCTURE/general/ICompany";
import { getCompanyProfile } from "../../../services_NEW_STRUCTURE/Profile/CompanyProfile.service";
import { isCancel } from "../../../helpers/AbortHelpers";
import { BLANK_ICOMPANY } from "../../../constants/blankData/company/blankCompany";
import { getStudentProfile } from "../../../services_NEW_STRUCTURE/Profile/InternProfile.service";
import { IIntern } from "../../../models_NEW_STRUCTURE/general/IIntern";
import { BLANK_IINTERN } from "../../../constants/blankData/intern/blankIntern";
import {
  MessageContext,
  MessageDispatchContext,
} from "../../../context/MessageContext";
import { MessageReducerType } from "../../../reducers/messagingReducer";
import { IConversationResponse } from "../../../models/messaging/IConversationResponse";
import ServiceResponse from "../../../models_NEW_STRUCTURE/ServiceResponses/ServiceResponse";
import PopupPremiumAlert from "../../popupAlert/PopupPremiumAlert";
import StandardizedPopupSubscriptionWithStripe from "../../subscriptionPopup/StandardizedPopupSubscriptionWithStripe";

interface IProps {
  data: IUserNavbarData[];
  userRole: string | undefined;
}

function Navbar({ data, userRole }: IProps) {
  const { user, companySubscription } = useContext(UserContext);
  const [routes, setRoutes] = useState<IRoutes[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const { size } = useScreenSize();
  // const [companydata, setCompanydata] = useState<ICompany>(BLANK_ICOMPANY);
  // const [studentData, setStudentData] = useState<IIntern>(BLANK_IINTERN);
  const [loaded, setLoaded] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  const { logout } = useContext(UserContext);
  const { socket, conversations, loading } = useContext(MessageContext);
  const dispatch = useContext(MessageDispatchContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!socket && user.id !== "") {
      dispatch({
        type: MessageReducerType.CREATE_SOCKET,
        payload: user.id,
      });
    }

    if (socket && conversations.length === 0 && !loaded) {
      dispatch({
        type: MessageReducerType.LOADED,
        payload: true,
      });

      socket.emit(
        "get_conversations",
        { role: user.role },
        (cs: ServiceResponse<IConversationResponse[]>) => {
          dispatch({
            type: MessageReducerType.LOADED,
            payload: false,
          });

          dispatch({
            type: MessageReducerType.GOT_CONVERSATIONS,
            payload: cs.data,
          });
        }
      );

      if (location.pathname.toLowerCase().indexOf("meddelanden") < 0) {
        dispatch({
          type: MessageReducerType.GOT_CONVERSATION,
          payload: undefined,
        });
      }

      socket.emit("join_all_rooms", { userId: user.id });

      socket.emit("has_unread", { userId: user.id }, (hasUnread: boolean) => {
        setHasUnreadMessages(hasUnread);
      });

      setLoaded(true);
    }

    socket?.on("new_message", () => {
      setHasUnreadMessages(true);
    });

    socket?.on("new_message_broadcast", (toUserId: string) => {
      if (user.id === toUserId) setHasUnreadMessages(true);
    });

    socket?.on("message_read", () => {
      socket.emit("has_unread", { userId: user.id }, (hasUnread: boolean) => {
        setHasUnreadMessages(hasUnread);
      });
    });

    for (let i = 0; i < data.length; i++) {
      if (userRole === data[i].userType) {
        if (userRole === "Company") {
          if (
            location.pathname === "/company/details" ||
            location.pathname === "/company/halfway" ||
            location.pathname === "/company/internship"
          ) {
            return setRoutes(data[4].routes);
          } else return setRoutes(data[i].routes);
        }

        if (userRole === "Student") {
          if (
            location.pathname === "/intern/addprofileimage" ||
            location.pathname === "/intern/addinternperiod" ||
            location.pathname === "/intern/addskills" ||
            location.pathname === "/intern/addpersonal" ||
            location.pathname === "/intern/addemployment"
          ) {
            return setRoutes(data[4].routes);
          }
        }

        return setRoutes(data[i].routes);
      }

      setRoutes(data[0].routes);
    }

    return () => {
      socket?.off("new_message");
      socket?.off("new_message_broadcast");
    };
  }, [
    data,
    userRole,
    routes,
    location,
    socket,
    user,
    dispatch,
    conversations,
    loading,
    loaded,
  ]);

  useEffect(() => {
    setIsMobile(size.width <= 1199 ? true : false);
  }, [size]);

  const logOutFunction = async () => {
    const res = await logOut();
    if (res.data.data) {
      // Update the title to "Hopen" when the user logs out
      document.title = "Hopen";
      navigate("/");
      logout(res.data.data);
      setIsOpen(false);
    } else {
      return;
    }
  };

  const handleHelp = () => {
    if (user.role !== undefined) {
      window.location.href = "/company/page/7";
    } else {
      window.location.href = "https://hopen.se/hjalp/";
    }
  };

  const handleLogo = () => {
    window.location.href = "https://app.hopen.se/";
  };

  const handleBookMeeting = () => {
    window.open("https://hopen.se/boka-mote/", "_blank");
  };

  const closeModal = () => {
    setOpenSubscriptionModal(!openSubscriptionModal);
  };
  return (
    <>
      {openSubscriptionModal && (
        companySubscription ? 
        <PopupPremiumAlert
          noBtnText="Logga in"
          yesBtnText="Skapa konto"
          yesAlertFunction={() => {
            navigate("/register");
          }}
          noAlertFunction={() => {
            navigate("/login");
          }}
          closeFunction={() => {
            setOpenSubscriptionModal(false);
          }}
          title="Hej!"
          message="För att använda denna funktion behöver du logga in eller skapa ett konto."
        /> :
        <StandardizedPopupSubscriptionWithStripe noAlertFunction={closeModal} updateStripeClicked={closeModal}/>
        
      )}
      <div id="nav-container">
        <div className="nav-relative-div">
          <img
            data-testid="logo-image"
            src={IMG_CONFIG.HOPEN_LOGOS.LOGO}
            alt="logo"
            className="logoImage-nav"
            onClick={handleLogo}
          />

          {isMobile ? (
            <>
              <div className="icon-container">
                {isOpen ? (
                  <div className="open-icon">
                    <IoClose
                      onClick={() => setIsOpen(!isOpen)}
                      className="menu-icon"
                      data-testid="close"
                    />
                  </div>
                ) : (
                  <div className={`closed-icon`}>
                    <IoMenu
                      onClick={() => setIsOpen(!isOpen)}
                      className="menu-icon"
                      data-testid="open"
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <nav className="nav-desktop">
              {routes.length > 0
                ? routes.map((link: IRoutes) => {
                    if (
                      !companySubscription &&
                      user.role !== "Student" &&
                      (link.name === "Ansökningar" ||
                        link.name === "Favoriter" ||
                        link.name === "Meddelanden")
                    ) {
                      return (
                        <span
                          className={`link-name-desktop`}
                          key={link.id}
                          onClick={() => setOpenSubscriptionModal(true)}
                        >
                          {link.name}
                        </span>
                      );
                    } else
                      return (
                        <Link
                          className={`link-name-desktop ${
                            hasUnreadMessages === true &&
                            link.name === "Meddelanden"
                              ? "blob blob--show"
                              : ""
                          }`}
                          to={link.route}
                          key={link.id}
                        >
                          {link.name}
                        </Link>
                      );
                  })
                : null}

              {user.id !== "" && (
                <button className="link-name-desktop" onClick={handleHelp}>
                  Hjälp?
                </button>
              )}
              {!userRole || userRole === "" ? null : (
                <button className="logout-btn" onClick={logOutFunction}>
                  Logga ut
                </button>
              )}
            </nav>
          )}

          {isMobile ? (
            <>
              {isOpen ? (
                <div className="menu-options">
                  {/* <button className="link-name" onClick={handleAboutUs}>
									Om oss
								</button> */}
                  {routes.map((link: IRoutes) => {
                    return (
                      <Link
                        to={link.route}
                        className={`link-name ${
                          hasUnreadMessages === true &&
                          link.name === "Meddelanden"
                            ? "blob blob--show"
                            : ""
                        }`}
                        key={link.id}
                        onClick={() => setIsOpen(false)}
                      >
                        {link.name}
                      </Link>
                    );
                  })}
                  <button className="link-name" onClick={handleHelp}>
                    Hjälp?
                  </button>
                  {!userRole || userRole === "" ? null : (
                    <button className="logout-btn" onClick={logOutFunction}>
                      Logga ut
                    </button>
                  )}
                </div>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Navbar;
